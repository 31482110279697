//react
//mui components
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const InputFilledWithCustomLabel = ({
  height = "1.125rem",
  label,
  customSx,
  children,
  enableMinWidth = false,
  typographyVariant = "11px-med",
  childrenWidth = "11.5625rem",
}) => {
  const theme = useTheme();
  let sx = {
    fontFamily: "Roboto Mono",
    color: theme.palette.secondary.accent2,
    minWidth: "5.25rem",
    flexShrink: 0,
  };
  Object.assign(sx, customSx);
  return (
    <Grid
      item
      height={height}
      py={4}
      boxSizing={"content-box"}
      sx={{
        background: theme.palette.secondary.accent1,
        borderRadius: "2.5rem",
      }}
    >
      <Grid
        container
        flexDirection={"row"}
        alignItems="baseline"
        justifyContent="space-between"
        width={"100%"}
        height={"100%"}
        px={4}
      >
        <Typography as={"div"} variant={typographyVariant} align="left" sx={sx}>
          {label}
        </Typography>
        <Grid item width={childrenWidth} flexGrow={1}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputFilledWithCustomLabel;
