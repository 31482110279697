//react components
//css library
import styled from "@emotion/styled";
//mui components
import MuiTextField from "@mui/material/TextField";
import { spacing } from "@mui/system";
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)``;
//sqlitecloud components
import InputWithCustomLabel from "@generic-components/Form/InputWithCustomLabel";

const ModalSelect = (props) => {
  return (
    <InputWithCustomLabel {...props}>
      <TextField
        fullWidth
        select={true}
        className="subvariant-modal-form"
        value={props.value}
        onChange={props.onChange}
      >
        {props.children}
      </TextField>
    </InputWithCustomLabel>
  );
};

export default ModalSelect;
